import React from "react";

function ContestIcon() {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.30456 7.57295C9.19905 7.64953 9.05922 7.6596 8.94382 7.59893L7.92181 7.06162L6.89983 7.59892C6.78457 7.65952 6.64492 7.64956 6.53943 7.57321C6.43358 7.4966 6.3805 7.36646 6.40259 7.23767L6.59777 6.09965L5.77124 5.29397C5.67797 5.20305 5.64427 5.06714 5.68426 4.94317C5.7244 4.81871 5.83186 4.72792 5.96128 4.70912L7.1035 4.54314L7.61422 3.50834C7.672 3.39126 7.79125 3.31713 7.92181 3.31713C8.05237 3.31713 8.17161 3.39126 8.2294 3.50834L8.74012 4.54314L9.88235 4.70912C10.0115 4.72789 10.1188 4.81836 10.1592 4.9425C10.1995 5.06663 10.1658 5.20289 10.0724 5.29399L9.24585 6.09965L9.44103 7.23769C9.46309 7.36631 9.41018 7.4963 9.30456 7.57295ZM8.67299 6.70335L8.52954 5.8669L9.13726 5.27452L8.29741 5.15248L7.92181 4.39145L7.54621 5.15248L6.70637 5.27452L7.31408 5.86691L7.17063 6.70336L7.92181 6.30843L8.67299 6.70335Z"
				fill="#8C8594"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M4.31478 1.33325C3.94659 1.33325 3.64811 1.63173 3.64811 1.99992V3.14729H1.99967C1.63148 3.14729 1.33301 3.44576 1.33301 3.81395V5.73453C1.33301 6.41139 1.7088 6.96418 2.18192 7.3244C2.6585 7.68725 3.27989 7.92036 3.8681 7.92036H3.92489C4.29044 8.84776 4.99879 9.62274 5.93374 10.0622L6.41051 10.2863V11.2846H5.95763C5.09901 11.2846 4.40299 11.9806 4.40299 12.8393V13.9999C4.40299 14.3681 4.70147 14.6666 5.06966 14.6666H10.9297C11.2979 14.6666 11.5963 14.3681 11.5963 13.9999V12.8393C11.5963 11.9806 10.9003 11.2846 10.0417 11.2846H9.58863V10.2863L10.0654 10.0622C11.0003 9.62274 11.7087 8.84776 12.0743 7.92036H12.1309C12.719 7.92036 13.3406 7.68727 13.8173 7.32446C14.2905 6.9643 14.6663 6.41151 14.6663 5.73453V3.81332C14.6663 3.44505 14.3677 3.14654 13.9995 3.14665L12.351 3.14714V1.99992C12.351 1.63173 12.0526 1.33325 11.6844 1.33325H4.31478ZM2.2998 4.14729H3.64811V6.46222C3.64811 6.61163 3.65648 6.75973 3.67286 6.90598C3.37644 6.86381 3.05278 6.73059 2.7877 6.52877C2.45943 6.27883 2.2998 5.9887 2.2998 5.73453V4.14729ZM8.58863 11.2846H7.41051V9.65144L6.35915 9.15722C5.31481 8.66632 4.64811 7.61621 4.64811 6.46222V2.9866C4.64811 2.61841 4.94659 2.31993 5.31478 2.31993H10.6844C11.0526 2.31993 11.351 2.61841 11.351 2.9866V6.46222C11.351 7.61621 10.6843 8.66632 9.63999 9.15722L8.58863 9.65144V11.2846ZM13.2117 6.52871C12.9465 6.73053 12.6227 6.86376 12.3263 6.90596C12.3427 6.75972 12.351 6.61162 12.351 6.46222V4.14714L13.6998 4.14675V5.73453C13.6998 5.98859 13.5401 6.27871 13.2117 6.52871ZM5.40299 12.8393C5.40299 12.5329 5.6513 12.2846 5.95763 12.2846H10.0417C10.348 12.2846 10.5963 12.5329 10.5963 12.8393V13.6666H5.40299V12.8393Z"
				fill="#8C8594"
			/>
		</svg>
	);
}

export default ContestIcon;
